.blog-header{
    padding-top: 200px;
}
.blog-header h1{
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 60px;
    font-weight: 300;
    color: #B9AA7D;
}
.blog-header p{
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
}
.blog-grid .blog-card{
    margin-bottom: 120px;
}
.blog-card h2{
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    color: #B9AA7D;
}
.blog-card p{
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
}
.blog-card-btn{
    text-align: center;
}
.blog-card-btn a{
    padding: 8px 22px;
    border: 1px solid;
    font-size: 22px;
    font-weight: 300;
}