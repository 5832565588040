.modalOff{
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;    
    background: rgba(4, 9, 17, .8);
    z-index: 30; 
}
.modalOn{
    visibility: visible;
    opacity: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: rgba(4, 9, 17, .8);
    z-index: 30;  
}
.modalContent{
    position: relative;
}

.modalContent img{
    width: 100%;
    cursor: initial;    
}
.modalContent img:hover{
    filter: none !important;
}
.modalContent div{
    cursor: pointer;
    padding: 2em;
    position: absolute;
    top: 0;
    right: 0;
}
.modalContent span{
    background: #fff;
    height: 3px;
    width: 2.5em;
    display: block;
    transform: rotate(45deg);    
}
.modalContent span::after{
    content: '';
    display: inline-block;    
    background: #fff;
    height: 3px;
    width: 2.5em;
    transform: rotate(90deg) translate(-11px, 0px);
}

.modalBtns{
    display: flex;
    width: 75%;
    position: absolute;
    justify-content: space-between;
    
}
.modalBtns div{
    color: #fff;
    font-size: 3em;
    cursor: pointer;    
}
