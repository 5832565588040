.navList, .navListActive{    
    padding-inline-start: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    transition: .5s ease;
    display: flex;
    align-items: center;    
}
@media (max-width: 999px){    
    .navList, .navListActive{        
        position: absolute;
        padding: 1em 3em;
        top: 110px;
        right: 0;
        width: 300px;
        flex-direction: column;
        align-items: stretch;
        transition: .5s ease;        
        z-index: 10;        
    }
    .navList{
        transform: translateX(101%);
    }
    .navListActive{
        transform: translateX(0%);
    }
}