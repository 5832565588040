.container {
    overflow: hidden;
    position: relative;    
  }
  
  .content {
    display: flex;
    z-index: 100;
    transition: transform 0.3s ease;
  }
  
  .item {
    flex-shrink: 0;
    width: 80%;
    margin: 0 10%;
    border-radius: 4px;   
    padding: 15rem 5rem;
  }
  
  .nav {
    color:#b9aa7d; 
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .nav div{
      cursor: pointer;
  }
  .nav div i{
      font-size: 3em;
      color:#b9aa7d;
  }