.grid1 {width: calc(8.33333% - 20px);}
.grid2 {width: calc(16.66667% - 20px);}
.grid3 {width: calc(25% - 20px);}
.grid4 {width: calc(33.33333% - 20px);}
.grid5 {width: calc(41.66667% - 20px);}
.grid6 {width: calc(50% - 20px);}
.grid7 {width: calc(58.33333% - 20px);}
.grid8 {width: calc(66.66667% - 20px);}
.grid9 {width: calc(75% - 20px);}
.grid10 {width: calc(83.33333% - 20px);}
.grid11 {width: calc(91.66667% - 20px);}
.grid12 {width: calc(100% - 20px);}

[class^="Grids_grid"] {
    /* margin: 0 10px;  ESTE É O PADÃO ORIGINAL*/
    margin: 10px;
}

@media screen and (min-width: 768px) and (max-width: 959px){
    [class^="Grids_grid"] {width: calc(50% - 20px);}
    [class^="Grids_grid12"] {width: 100%;}
}

@media screen and (max-width: 767px){
    [class^="Grids_grid"] {width: 100%;}
}