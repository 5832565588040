.contato-header{
    padding: 100px 0 100px 0;
    background: #5e5e5e;
}
.contato-header h1{
    margin-block-end: 0em;
    font-size: 60px;
    font-weight: 300;
    color: #B9AA7D;
}
.contato-header p{
    font-size: 18px;
    font-weight: 300;
    color: #eee;
}

.contato-info address{
    margin-bottom: 40px;
    font-style: normal;
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
    line-height: 1.3em;
}

.contato-btn a{
    color: #fff;
    padding: 20px 0px;
    background: #39b54a;    
    display: flex;
    width: 250px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    border-radius: 10px;
}
.contato-btn a i{
    font-size: 2em;
    margin-right: 30px;
}
.contato-info input, .contato-info textarea{
    padding: 5px;
    width: 100%;
    font-size: 18px;
    margin-bottom:20px;
}
.contato-info span{
    display: block;
    margin-top: -20px;
    margin-bottom:20px;
}
.contato-info button{
    margin: 10px 0 0 auto;
    display: block;
    background: #adcadc;
    width: 50%;
    padding: 15px 0;
    border-style: none;
    color: white;
    font-size: 20px;
}
