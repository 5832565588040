.sobre-header{
    padding-top: 200px;
}
.sobre-header h1{
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 60px;
    font-weight: 300;
    color: #B9AA7D;
}
.sobre-header p{
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
}


.sobre-info .info-img{
    position: relative;
    left: 50px;
}
.sobre-info .info-text{
    border: 1px solid;
    padding: 1em 2em 1em 4em;
}
.sobre-info p{
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;   
}

.sobre-carosel .carosel-text{
    display: flex;
    justify-content: center;
}
.sobre-carosel p{
    width: 80%;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
}

@media (max-width: 767px){
    .sobre-info .info-img{
        position: relative;
        left: 0px;
        top: 40px;        
    }
}