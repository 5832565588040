.produto-header{
    padding: 100px 0 100px 0;
    background: #5e5e5e;
}
.produto-header h1{
    margin-block-end: 0em;
    font-size: 60px;
    font-weight: 300;
    color: #B9AA7D;
} 
.produto-header p{
    font-size: 18px;
    padding: 0 calc(10%);
    font-weight: 300;
    color: #eee;
}

/**/
.produtos h2 a{
    color: #B9AA7D;
    font-weight: 400;
    font-size: 2rem;
    text-transform: capitalize;
}
.produtos h2 a span{
    font-size: 18px;
    padding: 0 20px;
    font-weight: 300;
    color: #5e5e5e;
}

/**/

.card-produto img{    
    cursor: pointer;
    transition: .3s;    
}
.card-produto img:hover{
    filter: brightness(75%);
}
.card-produto h2{
    font-size: 26px;
    font-weight: normal;
    text-align: center;
    color: #B9AA7D;
}
hr{
    border-bottom: 1px;
    border-color: #B9AA7D;
    opacity: .5;
}