.mobileMenu{
    cursor: pointer;
    width: 50px;
    height: 50px;    
    display: none;        
}
.mobileMenu i{
    font-size: 1.7em;
    color: #b9aa7d;
}
@media (max-width: 999px){
    body{
        overflow-x: hidden;
    }
    .mobileMenu{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
