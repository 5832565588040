.home-servicos img{
    cursor: pointer;
}
.home-servicos h2{
    font-size: 26px;
    color: #B9AA7D;
    font-weight: normal;
}
.home-servicos p{
    font-size: 20px;
    color: #5E5E5E;
    font-weight: 300;
}

.home-video h1{
    font-size: 60px;
    font-weight: 300;
    color: #B9AA7D;
}
.home-video p{
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;
}

.home-equipe{
    height: 570px;
    background: url(../assets/bg/equipe.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home-equipe .equip{
    flex-direction: column;
}
.home-equipe-link{
    margin-top: -10px;
    margin-left: calc(66.66667% + 10px);
}
.home-equipe-link a{
    padding: 25px 0px;
    width: 160px;
    display: inline-block;
    text-align: center;
    font-weight: 300;
    background: #fff;
    transition: .3s;
}
.home-equipe-link a:hover{
    background: #DBB7A0;
    color: #fff;
}

.desc{
    margin-top: 80px;
    padding: 3.5em;
    background: #DBB7A0;
}
.desc h2{
    margin-block-start: 0em;
    margin-block-end: 0.3em;
    font-size: 50px;
    font-weight: 300;
    color: #fff;
}
.desc p{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
}

.home-newsletter h1{
    text-align: right;
    padding-right: 60px;
    font-size: 50px;
    font-weight: 300;
    color: #5E5E5E;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.home-newsletter p{
    padding-left: 60px;
    font-size: 20px;
    font-weight: 300;
    color: #5E5E5E;    
}

.newsletter{
    margin-top: 80px;
    padding: 90px 0;
    background: #EEF0F1;
}
.newsletter h3{
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 28px;
    color: #5E5E5E;
    font-weight: 300;
}
.newsletter form{
    text-align: center;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newsletter input{
    width: 35%;    
    border-style: none;
    padding: 10px 5px;
    text-align: center;
    font-size: 20px;
    color: #5E5E5E;
}
.newsletter button{
    padding: 10px 40px;
    border-style: none;
    margin-left: 20px;
    font-size: 20px;
    background: #ADCADC;    
    color: #fff;
    cursor: pointer;
    transition: .5s;
}
.newsletter button:hover{
    background: #5e5e5e;
}
.newsletter button:disabled{
    background: #c1c1c1 !important; 
    cursor: default; 
}

