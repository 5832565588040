.container {
  overflow: hidden;
  position: relative;
}

.content {
  display: flex;
  z-index: 1;
  transition: transform 0.3s ease;
}

.item {
  flex-shrink: 0;
  width: 100%; 
  text-align: center;   
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
.title{
  color: #B9AA7D;
  font-size: 49px;
  font-weight: 300;
  
}
.btnSlide{
  margin-top: 2em;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  display: block;
  padding: 20px 0;
  width: 250px;
  border: 1px solid;
  z-index: 20;
}

.slideInfo{
  text-align: left;
  background: #b9b9ad;
  padding: 50px 40px;
  align-self: flex-start;
  margin-left: calc(20%);
  width: 350px;
  color: white;
}
.slideInfo h1{
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  font-size: 50px;
  font-weight: 300;
}
.slideInfo p{
  font-weight: 300;
  font-size: 18px;
}

.slideLink{
  display: block;
  width: 180px;
  background: #fff;  
  padding: 30px 40px;
  align-self: flex-start;
  margin-left: calc(20%);
  font-weight: 300;
  text-align: left;
}
  