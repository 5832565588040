@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
*,*::after,*::before{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family:'Roboto', sans-serif; 
}
img{
  display: block;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul{
  list-style: none;
}
a{  
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

/* INICIO Layout System */
.container{
  max-width: 1200px;
  /* margin: 0 auto; ESTE É O PADÃO ORIGINAL*/
  margin: 0px auto;
  min-height: 1px;
  display: flex; 
}

.wrap{flex-wrap: wrap;}
.flexspace-between{justify-content: space-between;}
.flexspace-around{justify-content: space-around;}
.flexalign-center{align-items: center;}
.flex-center{justify-content: center;}



div.offset-1 {margin-left: calc(8.33333% + 10px);}
div.offset-2 {margin-left: calc(16.66667% + 10px);}
div.offset-3 {margin-left: calc(25% + 10px);}
div.offset-4 {margin-left: calc(33.33333% + 10px);}
div.offset-5 {margin-left: calc(41.66667% + 10px);}
div.offset-6 {margin-left: calc(50% + 10px);}
div.offset-7 {margin-left: calc(58.33333% + 10px);}
div.offset-8 {margin-left: calc(66.66667% + 10px);}
div.offset-9 {margin-left: calc(75% + 10px);}
div.offset-10 {margin-left: calc(83.33333% + 10px);}
div.offset-11 {margin-left: calc(91.66667% + 10px);}
div.offset-12 {margin-left: calc(100% + 10px);}


@media screen and (min-width: 960px) and (max-width: 1199px) {
  .container {max-width: 960px;}
}
@media screen and (min-width: 768px) and (max-width: 959px) {
  .container {max-width: 768px;}  
}
@media screen and (max-width: 767px){
  div.offset-1, div.offset-2, div.offset-3, 
  div.offset-4, div.offset-5, div.offset-6, 
  div.offset-7, div.offset-8, div.offset-9, 
  div.offset-10, div.offset-11, div.offset-12 {margin-left: 10px;}

  .container {max-width: 480px;}
}

/* FIM Layout System */

/* INicio CLasses Typograficas */
  .text-center{text-align: center;}

/* Fim CLasses Typograficas */












